







































import { Component, Prop } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import TranslatableComponent, {
  Translatable,
} from "@/lib/mixins/translateable";
import { UserManagementUser } from "@/models/user";
import accessControl from "@/api/access_control";
import Loader from "@/components/Loader.vue";
import AuthsLoader from "@/views/usermanagement/employees/AuthsLoader.vue";
import TheHeader from "@/components/TheHeader.vue";
import Page from "@/components/layouts/Page.vue";

@Component({
  components: { Loader, AuthsLoader, Page, TheHeader },
})
@Translatable("usermanagement.employees")
export default class UserDetail extends mixins<TranslatableComponent>() {
  public loading: boolean = false;

  UserManagementUser = UserManagementUser;

  private user: UserManagementUser | null = null;

  private userFullName: string = "";

  @Prop({ required: true, type: String })
  readonly accountId!: string;

  @Prop({ required: true, type: String })
  readonly profileId!: string;

  mounted() {
    this.loading = true;
    accessControl.usermanagement.contacts
      .contact(this.profileId)
      .then((response) => {
        this.user = response.data;
        this.userFullName = this.user.fullName;
      })
      .catch(() => {
        this.user = null;
        this.$snotify.error(
          `${this.translated_view_value("profile_load_error")}`
        );
      })
      .finally(() => (this.loading = false));
  }
}
